import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Doclogindtl } from "../model/doclogindtl";
import { AuthenticationService } from "../_service/authentication.service";
import { AlertService } from "../_service/alert.service";
import { CustomerLoginService } from "../_service/customer-login.service";
import { DialogOpenService } from "../_service/dialog-open.service";
import { User } from "../model/user";
import { HttpClient } from "@angular/common/http";
import { DocMobVarifictionService } from "../_service/doc-mob-varifiction.service";
import { DoctorContactDto } from "../model/doctor-contact-dto";
import { DocContactInfoUpDto } from "../model/doc-contact-info-up-dto";
import { ResponseDto } from "../model/response-dto";
import { ApiConstants } from "../model/api-constants";
import { MessagingService } from "../_service/messaging.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { KJUR } from 'jsrsasign';
import { PropertiesConstants } from "../model/properties-constants";
import *  as $ from 'jquery';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, AfterViewInit {
  showflash = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  successStr = false;
  verifyOTPValue = false;
  emailForPassChange: string;
  userInput: Doclogindtl;
  validateLogin = false;
  docDtl: User;
  mobvarflag = "N";
  mobileValidationFlag = true;
  invalidNoDB = false;
  countryCode = "91";
  mobileNo = ""; //typed correct mobile no
  smsOTP = ""; // otp in message on provide no
  variOtpFlag = false;
  apiOtp = "";
  sendOtpStr = "Send_OTP";
  doctorContactDto: DoctorContactDto;
  otpMobileNo = ""; // otp sent mobile no
  otpEmail = "";
  newPassCheck = "";
  newPass = "";
  updatePass = false;
  private pathPrefix: string;
  litmusLogoImg = "";
  googlPlayImg = "";
  qrCodeImg = "";
  textBody = "null";
  //browser used to open this application
  usedBrowserName = "";
  loginButtonDisFlag = false;
  loginLoader = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private loginService: CustomerLoginService,
    private modalService: DialogOpenService,
    private http: HttpClient,
    private mobVarificationService: DocMobVarifictionService,
    private messagingService: MessagingService,
    private toastrService: ToastrService,
    public translate: TranslateService
  ) {
    this.userInput = new Doclogindtl();
    this.docDtl = new User();
    this.doctorContactDto = new DoctorContactDto();

    //img paths set ==>
    this.pathPrefix = ApiConstants.pathPrefix;
    if (this.pathPrefix === "") {
      this.pathPrefix = "../../assets/";
    }
    this.litmusLogoImg = this.pathPrefix + "images/logo_litmusDx.png";
    this.googlPlayImg = this.pathPrefix + "images/icon_googlePlay.svg";
    this.qrCodeImg = this.pathPrefix + "images/icon_qrCode.svg";
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });

    $(".toggle-password").on('click', function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    this.createCaptcha();

    // Listen for the message
    window.addEventListener('message', (event)=> {
      // Verify that the message is coming from test.com
      if (event.origin === ApiConstants.doctorFaceLivenessUrl) {
        // Handle the received data
        var receivedData = event.data;
        console.log('Received data from ',ApiConstants.doctorFaceLivenessUrl,": ", receivedData);
        this.faceLogin(receivedData);
        this.childWindow.close();
      }
    });


  }

  ngAfterViewInit() {
    //load country script
    // this.loadScript("passwordValidationAfterview.js");
  }

  public loadScript(name: string) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/customJs/' + name;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  // create captcha==>
  code;
  input_captcha_log = "";
  createCaptcha() {
    //clear the contents of captcha div first 
    document.getElementById('captcha_log').innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 5;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 20);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.code = captcha.join("");
    document.getElementById("captcha_log").appendChild(canv); // adds the canvas to the body element
  }

  validateCaptcha(): boolean {
    //event.preventDefault();
    //debugger
    if ((<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value === this.code) {
      return true;
    } else {
      this.createCaptcha();
      return false;
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  // login form submit
  securePasswordChangeFlag = false;
  disclaimerMessagesList = [];
  onSubmit() {
    // check weather fcm notification is allowed or not?
    // if (!this.fcmPermission()) {
    //   // show modal of instruction to allow notification for specific browser
    //   //open notification instruction modal
    //   this.openNotificationInstructionModal();
    //   return false;
    // }


    // captcha validation
    if (!(<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value && "" === (<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value) {
      this.toastrService.warning(this.translate.instant("HOME.Provide_Captcha") + "!!");
      document.getElementById("cpatchaTextLogin").focus();
      return false;
    }

    if (!this.validateCaptcha()) {
      this.toastrService.error(this.translate.instant("HOME.Invalid_Captcha") + "!!");
      (<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value = "";
      document.getElementById("cpatchaTextLogin").focus();
      return false;
    }

    this.submitted = true;
    this.validateLogin = false;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    var md5 = require('md5');
    this.loading = true;
    this.loginButtonDisFlag = true;
    setTimeout(() => { this.loginButtonDisFlag = false; }, 6000);
    this.userInput.userId = this.f.email.value;
    this.userInput.loginPwd = this.f.password.value;
    this.userInput.loginMode = "W";
    this.loginLoader = true;
    //console.log(String(md5(this.f.password.value)).toLocaleUpperCase())
    let payload = {
      iss: "GLOCAL Healthcare",
      iat: 1598626868,
      exp: 1830162907,
      aud: "https://hellolyf.com/",
      sub: this.f.email.value,
      username: this.f.email.value,
      //password: String(this.f.password.value),
      password: String(md5(this.f.password.value)).toLocaleUpperCase(),
      role: "doctor",
      key: PropertiesConstants.secret_key,
      latitude: "28.7476999",
      longitude: "51.42763999",
      loginMode: "W",
      machineIpAddress: "192.168.5.33",
      countryName: "IN"
    }
    //console.log(payload);
    var oHeader = { alg: 'HS512', typ: 'JWT' };
    var sHeader = JSON.stringify(oHeader);
    var sPayload = JSON.stringify(payload);
    var tokenCF = KJUR.jws.JWS.sign("HS512", sHeader, sPayload, { utf8: PropertiesConstants.secret_key });
    //console.log("token::", tokenCF);
    localStorage.setItem("JWT_KEY", String(tokenCF));
    this.loginService.userLogin().subscribe((resp) => {
      // this.loginService.getLitmusUserLoginDtl(this.userInput).subscribe(
      // (resp) => {
      this.loginLoader = false;
      console.log("Doctor Detail on login ::",resp);
      this.docDtl = JSON.parse(resp.headers.getAll("data")[0]);
      let secureToken = resp.headers.getAll("token")[0];
      

      //and relogin after password change
      this.createCaptcha();

      this.mobvarflag = this.docDtl["mobileVerficationFlag"];
      localStorage.setItem("JWT_KEY", secureToken);
      //console.log(this.docDtl, secureToken);
      //this.mobvarflag = resp["mobileVerficationFlag"];
      //console.log(this.docDtl);
      if (this.docDtl) {

        let faceLogin = this.docDtl["faceLogin"];
        let faceId = this.docDtl["faceId"];

        if(faceLogin === "Y"){
          if(faceId != null && faceId != ""){
            this.toastrService.info("Please login using your facial biometrics.");
            this.openFaceLoginWindow();
            return;
          }
        }


        let value = JSON.stringify(this.docDtl);
        let key = "LITMUS_USER_DETAILS";
        if (this.docDtl.resStatus === "1" || this.docDtl.resStatus === "3") {
          //console.log("check mobile number");
          // just after login update to a secure password by changing password mandatory===>
          if (!this.passwordPolicyValidator(String(this.f.password.value))) {
            this.securePasswordChangeFlag = true;
            this.verifyOTPValue = true;
            this.openFPModal("forgotPassDlg");
            this.createCaptchaforFP();
            this.loadScript("passwordValidationAfterview.js");
            $(".toggle-password").on('click', function () {
              $(this).toggleClass("fa-eye fa-eye-slash");
              var input = $($(this).attr("toggle"));
              if (input.attr("type") == "password") {
                input.attr("type", "text");
              } else {
                input.attr("type", "password");
              }
            });
            return false;
          }

        } else if (
          this.docDtl.resStatus === "4" ||
          this.docDtl.resStatus === "5"
        ) {
          let key2 = "APPLI_STATUS";
          let value2 = JSON.stringify(this.docDtl);
          this.authenticationService.setAuthKey(key2, value2);
        } else {
          // create new captcha on failed login
          this.createCaptcha();
          (<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value = "";
          this.validateLogin = !this.authenticationService.isLoggedIn();
          this.toastrService.error(
            this.translate.instant("HOME.Invalid_user_name_or_Password")
          );
          //alert("invalid user mail or password");
        }

        if (this.docDtl.resStatus === "1") {
          // if mobile varification flag is Y than show modal else redirect to dashboard direct without showing dialog
          if (
            this.docDtl.userMobileNo === null ||
            this.docDtl.userMobileNo === ""
          ) {
            this.invalidNoDB = true;
            this.mobileValidationFlag = false;
          }

          // console.log("Disclaimer messages::1", this.docDtl['renderLoginDisclaimer']);
          // console.log("Disclaimer messages::2", this.docDtl['userType'] === 'Doctor');
          if (this.docDtl['renderLoginDisclaimer'] === "Y" && this.docDtl['userType'] === 'Doctor') {
            this.loginService.getDisclaimerMessages('DOCTOR').subscribe(
              (res) => {
                console.log("Disclaimer messages::", res);
                this.disclaimerMessagesList = res;
                if (this.disclaimerMessagesList && this.disclaimerMessagesList.length > 0) {
                  // show in modal
                  this.openDisclaimerModal();
                } else {
                  // process next step;
                }
              },
              (error) => {
                console.error("Error::login component::", error);
              }
            );
          } else {
            if (this.mobvarflag === "Y") {
              //alert("open mobile varification called===>");
              let modalId = "mobVariModal";
              // this will open mobile varificat modal ====>
              this.openModal(modalId);
            } else {
              //console.log("routing to dashboard===>");

              //set token to local storage ===>
              if (
                this.docDtl.resStatus === "1" ||
                this.docDtl.resStatus === "3"
              ) {
                this.authenticationService.setAuthKey(key, value);
              }

              //update fcm token ===>
              this.messagingService
                .updateTokenToDB(this.docDtl["doctorId"])
                .subscribe(
                  (res) => {
                    //console.log("response token fcm==>", res);
                    if (res && res["returnValue"] === "1") {
                      console.log("FCM Token successfully Updated!!");
                    } else {
                      //console.log("Error happen during api");
                    }
                  },
                  (error) => {
                    console.log("Error:: ", error);
                  }
                );
              this.toastrService.success(
                this.translate.instant("HOME.logged_in_Successfully") + ":)"
              );
              sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
              localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
              this.router.navigate(["/", "dashboard"]);
            }
          }
        }
        if (this.docDtl.resStatus === "3") {
          this.router.navigate(["dashboard/profile-settings"]);
        }
        if (this.docDtl.resStatus === "4" || this.docDtl.resStatus === "5") {
          sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
          this.router.navigate(["/", "applicationStatus"]);
        }
      } else {
        this.toastrService.error(
          this.translate.instant("HOME.Error_while_login") + "!!",
          this.translate.instant("HOME.Please_try_again") + "!!"
        );
        //alert('Error while login!!')
      }
    },
      (error) => {
        // create new captcha on failed login
        this.createCaptcha();
        (<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value = "";
        console.log("Error:: ", error);
        this.loginLoader = false;
        if (error && 500 === error['status']) {
          this.toastrService.error(
            this.translate.instant("HOME.Invalid_credential") + "!!"
          );
        } else {
          this.toastrService.error(
            this.translate.instant("HOME.Error_while_login") + "!!",
            this.translate.instant("HOME.Please_try_again") + "!!"
          );
        }
      }
    );

  }

  // disclaimer start here
  acceptDiscalimerFlag = false;
  openDisclaimerModal() {
    this.modalService.open('disclaimerModal');
  }

  closeDisclaimerModal() {
    this.modalService.close('disclaimerModal');
  }

  //process discalimer next process
  disclaimerProceedBtnTitle = "Select checkbox to proceed...";
  agreeCheckboxChange(event) {
    //console.log("flag::", this.acceptDiscalimerFlag, event);
    if (event) {
      this.disclaimerProceedBtnTitle = "";
    } else {
      this.disclaimerProceedBtnTitle = "Select checkbox to proceed...";
    }
  }
  disclaimerAgreeProcess() {
    this.closeDisclaimerModal();
    this.checkForMobileNoValidation();
  }

  checkForMobileNoValidation() {
    if (this.mobvarflag === "Y") {
      //alert("open mobile varification called===>");
      let modalId = "mobVariModal";
      // this will open mobile varificat modal ====>
      this.openModal(modalId);
    } else {
      //console.log("routing to dashboard===>");

      //set token to local storage ===>
      // update user detail on local storage ==>
      let value = JSON.stringify(this.docDtl);
      let key = "LITMUS_USER_DETAILS";
      this.authenticationService.setAuthKey(key, value);
      if (
        this.docDtl.resStatus === "1" ||
        this.docDtl.resStatus === "3"
      ) {
        this.authenticationService.setAuthKey(key, value);
      }

      //update fcm token ===>
      this.messagingService
        .updateTokenToDB(this.docDtl["doctorId"])
        .subscribe(
          (res) => {
            //console.log("response token fcm==>", res);
            if (res && res["returnValue"] === "1") {
              console.log("FCM Token successfully Updated!!");
            } else {
              //console.log("Error happen during api");
            }
          },
          (error) => {
            console.log("Error:: ", error);
          }
        );
      this.toastrService.success(
        this.translate.instant("HOME.logged_in_Successfully") + ":)"
      );
      sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
      localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
      this.router.navigate(["/", "dashboard"]);
    }
  }
  // disclaimer start here

  openNotificationInstructionModal() {
    //check  fcm notification permission
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    this.usedBrowserName = browserName;
    //console.log("browser name::: version:::", this.usedBrowserName);
    //open instruction Modal
    this.modalService.open('NotificationModal');
  }

  //close instruction Modal
  closeNotictionInstructionModal() {
    this.modalService.close('NotificationModal');
  }

  // check if fcm permission is given==>
  fcmPermission() {
    if (sessionStorage.getItem('FCMTOKEN') && "" !== sessionStorage.getItem('FCMTOKEN')) {
      return true;
    }
    return false;
  }

  onSubmit2() {
    if (this.mobvarflag === "Y") {
      //alert("open mobile varification called===>");
      let modalId = "mobVariModal";
      // this will open mobile varificat modal ====>
      this.openModal(modalId);
    } else {
      //console.log("routing to dashboard===>");
      this.toastrService.success(
        this.translate.instant("HOME.logged_in_Successfully") + ":)"
      );
      sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
      localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
      this.router.navigate(["/", "dashboard"]);
    }
  }

  sendOtp() {
    // check validation mail id ===>
    if (!this.isEmail(this.emailForPassChange)) {
      this.toastrService.warning(
        this.translate.instant("HOME.Enter_a_valid_email_address") + "!!"
      );
      //alert("Enter a valid email address!!");
      return false;
    }
    let responseDto = new ResponseDto();
    //send otp to register emailid===>
    this.loginService.generateOtpFP(this.emailForPassChange).subscribe(
      (res) => {
        console.log("response to get otp", res);
        responseDto = res;
        this.apiOtp = res["returnValue"];
        if (this.apiOtp === "0") {
          this.toastrService.error(
            this.emailForPassChange +
            " " +
            this.translate.instant("HOME.not_a_register_Email") +
            "!!"
          );
          //alert(this.emailForPassChange +" not a register Email!!");
        } else {
          this.sendOtpStr = "Resend_OTP";
          this.otpEmail = this.emailForPassChange;
          this.toastrService.info(
            this.translate.instant("HOME.OTP_sent_to_your_register_mobile_number") +
            "!!");
          //alert('otp send to register emailid:'+ this.emailForPassChange);
        }
      },
      (error) => {
        console.log("Error==>", error);
      }
    );
  }

  isEmail(search: string): boolean {
    var serchfind: boolean;
    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    //console.log(serchfind)
    return serchfind;
  }

  // verify sent otp over phone
  verifyOTPFP() {
    // verify send otp
    if (this.smsOTP === "") {
      this.toastrService.warning(
        this.translate.instant("HOME.Enter_Otp") + "!!"
      );
      return false;
    }
    if (this.apiOtp === this.smsOTP) {
      this.verifyOTPValue = true;

      // create captcha for forgot password model
      this.createCaptchaforFP();
      this.loadScript("passwordValidationAfterview.js");
      $(".toggle-password").on('click', function () {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
    } else {
      this.toastrService.warning(
        this.translate.instant("HOME.Invalid_OTP") +
        "!! " +
        this.translate.instant("HOME.try_newly_sent_otp") +
        "."
      );
    }
  }

  // captcha generation forgot password modal
  capchaCodeFP = "";
  capchaCodeFPstore;
  createCaptchaforFP() {
    //clear the contents of captcha div first 
    document.getElementById('captcha_fp').innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 5;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha_fp";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 20);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.capchaCodeFPstore = captcha.join("");
    document.getElementById("captcha_fp").appendChild(canv); // adds the canvas to the body element
  }

  validateCaptchaforFP(): boolean {
    //event.preventDefault();
    //debugger
    if (String(this.capchaCodeFP) === String(this.capchaCodeFPstore)) {
      return true;
    } else {
      this.createCaptchaforFP();
      return false;
    }
  }

  // change Passowrd ==> md5
  passwordValidErrorFlag = false;
  changePassword() {
    // captcha validation
    if (!this.capchaCodeFP && "" === this.capchaCodeFP) {
      this.toastrService.warning(this.translate.instant("HOME.Provide_Captcha") + "!!");
      document.getElementById("code_fp_text").focus();
      return false;
    }

    if (!this.validateCaptchaforFP()) {
      this.toastrService.error(this.translate.instant("HOME.Invalid_Captcha") + "!!");
      this.capchaCodeFP = "";
      //this.createCaptchaforFP();
      document.getElementById("code_fp_text").focus();
      return false;
    }
    if(null==this.newPass || ""===this.newPass || 6>this.newPass.length || 15<this.newPass.length){
      this.toastrService.warning("Pasword should be between 6 to 15 character length" + "!!");
      return false;
    }
    if (!this.passwordPolicyValidator(this.newPass)) {
      this.toastrService.warning(this.translate.instant("HOME.Provide_a_secure_password") + "!!");
      document.getElementById("password-fieldS").focus();
      return false;
    }

    if (this.newPass === "" || this.newPass === null) {
      this.toastrService.warning(
        this.translate.instant("HOME.Password_field_cant_be_empty") + "!!"
      );
      return false;
    }

    if (this.newPass !== this.newPassCheck) {
      this.toastrService.warning(
        this.translate.instant("HOME.Both_password_field_does_not_match") + "!!"
      );
      document.getElementById("password-fieldS").focus();
      return false;
    }


    // validate password requirements

    var md5 = require('md5');
    // change pass==>
    //updatePassword(this.emailForPassChange, String(md5(this.newPass)).toLocaleUpperCase())
    //updatePassword(this.emailForPassChange, this.newPass)
    if (!this.emailForPassChange || "" === this.emailForPassChange) {
      this.emailForPassChange = this.f.email.value;
    }
    this.loginService.updatePassword(this.emailForPassChange, String(md5(this.newPass)).toLocaleUpperCase()).subscribe(
      (res) => {
        // console.log("respose for update password", res);
        if (res && res === 1) {
          this.updatePass = true;
          this.toastrService.success(this.translate.instant('HOME.Password_Successfully_Updated') + "!!");
        } else if (res && res === -2) {
          this.toastrService.error(this.translate.instant('HOME.Provide_a_new_password_different_then_from_the_existing_password') + "!!", this.translate.instant('HOME.Password_change_failed') + "!!");
          this.createCaptchaforFP();
          this.capchaCodeFP = "";
        } else {
          this.toastrService.error(this.translate.instant('HOME.Please_try_again') + "!!", this.translate.instant('HOME.Error') + "!!");
          this.createCaptchaforFP();
          this.capchaCodeFP = "";
        }
      },
      (error) => {
        console.log("Error===>", error);
        this.createCaptchaforFP();
        this.capchaCodeFP = "";
        this.toastrService.error(this.translate.instant("HOME.Something_went_wrong") + "!!");
      });

  }

  // each key up check password validation ==>
  keyupPasswordCheck(event) {
    // console.log('password::', this.newPass, event);
    // console.log(this.passwordPolicyValidator(this.newPass));
    if (!this.passwordPolicyValidator(this.newPass)) {
      this.passwordValidErrorFlag = true;
    } else {
      this.passwordValidErrorFlag = false;
    }
    if (this.newPass !== this.newPassCheck) {
      this.passwordMatchFlag = true;
    }
  }

  passwordMatchFlag = false;
  keyupconfirmPassword(event) {
    if (this.newPass !== this.newPassCheck) {
      this.passwordMatchFlag = true;
    } else {
      this.passwordMatchFlag = false;
    }
  }

  closeFPModal() {
    this.emailForPassChange = "";
    this.sendOtpStr = "Send_OTP";
    this.verifyOTPValue = false;
    this.smsOTP = "";
    this.newPass = "";
    this.newPassCheck = "";
    this.updatePass = false;
    this.capchaCodeFP = "";
    this.securePasswordChangeFlag = false;
    this.cfpeyeslashclass = false;
    this.fpeyeslashclass = false;
    this.closeModal("forgotPassDlg");
  }

  // open dialog (modal using id)===>
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  // open forgot password modal
  openFPModal(event: string) {
    // open forgot password modal
    this.openModal(event);

  }

  correctMob() {
    // if mobile no is correct than route to dashboard---
    // console.log("routing to dashboard===> after check correct mobile no===>");
    this.closeModal("mobVariModal");

    // set details to local storage ===>
    let value = JSON.stringify(this.docDtl);
    let key = "LITMUS_USER_DETAILS";
    if (this.docDtl.resStatus === "1" || this.docDtl.resStatus === "3") {
      this.authenticationService.setAuthKey(key, value);
    }
    //update fcm token ===>
    this.messagingService.updateTokenToDB(this.docDtl["doctorId"]).subscribe(
      (res) => {
        //console.log("response token fcm==>", res);
        if (res && res["returnValue"] === "1") {
          console.log("FCM Token successfully Updated!!");
        } else {
          //console.log("Error happen during api");
        }
      },
      (error) => {
        console.log("Error:: ", error);
      }
    );
    this.toastrService.success(
      this.translate.instant("HOME.logged_in_Successfully") + " :)"
    );
    sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
    localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
    this.router.navigate(["/", "dashboard"]);
  }

  incorrectMob() {
    //if mobile no is not correct than open otp varification modal==
    this.mobileValidationFlag = !this.mobileValidationFlag;
  }

  generateOTP() {
    //new otp will send to your mobile no

    //validate===>
    if (this.mobileNo === null || this.mobileNo === "") {
      this.toastrService.warning(
        this.translate.instant("HOME.Mobile_number_cant_be_empty") + "!!"
      );
      //alert("Mobile number can't be empty!!.");
      return false;
    }
    // if(parseInt(this.mobileNo)/(10*10*10*10*10*10*10*10*10)<1){
    //   alert("Enter 10 digit valid number");
    //   return false;
    // }

    //api call ===>
    this.mobVarificationService.generateOTP(this.mobileNo).subscribe((res) => {
      //console.log("doc contact info", res);
      this.doctorContactDto = res; // otp on api call
      this.apiOtp = this.doctorContactDto["otp"];
      //console.log("otp otp===>"+this.apiOtp);
      if (this.apiOtp) {
        this.toastrService.success(
          this.translate.instant("HOME.OTP_sent_on_provided_mobile_number")
        );
        //alert("OTP sent to you mobile");
        this.sendOtpStr = "Resend_OTP";
        this.otpMobileNo = this.mobileNo;
      } else {
        this.toastrService.error(
          this.translate.instant("HOME.Please_try_again") + "!!",
          this.translate.instant("HOME.Failed") + "!!"
        );
        //alert("Error try Again!!");
      }
    }, (error) => {
      console.error("Error::", error);
    });
  }

  changeMobileNo() {
    //console.log("mobile number changed");
    if (this.otpMobileNo !== this.mobileNo) {
      this.sendOtpStr = "Send_OTP";
    }
  }

  changeEmail() {
    //console.log("mailid number changed");
    if (this.otpEmail !== this.mobileNo) {
      this.sendOtpStr = "Send_OTP";
    }
  }

  varificationOtp() {
    if (this.otpMobileNo === null || this.otpMobileNo === "") {
      this.toastrService.warning(
        this.translate.instant("HOME.Mobile_No_can_not_be_empty") + "!!!"
      );
      //alert("Mobile No. can not be empty!!!");
    }
    // on varification of otp update mobile number===>
    if (this.smsOTP === "") {
      this.toastrService.warning(
        this.translate.instant("HOME.Enter_Otp") + "!!"
      );
      //alert("Enter Otp!!");
      return false;
    }
    if (this.apiOtp === this.smsOTP) {
      //make api call to update doctor mobile number==>
      let obj = new DocContactInfoUpDto();
      if (this.docDtl.doctorId !== null) {
        //console.log("values==>id"+this.docDtl.doctorId+" litid"+this.docDtl.userCustomerId+" mob"+this.mobileNo);
        obj.countryCode = "+91";
        obj.doctorhellolyfId = this.docDtl.doctorId;
        obj.litmusId = this.docDtl.userCustomerId;
        obj.mobileNo = this.otpMobileNo;
      } else {
        this.toastrService.warning(
          this.translate.instant("HOME.Invalid_User"),
          this.translate.instant("HOME.Please_Login_again") + "!!"
        );
        //alert("session obj is empty");
      }
      this.mobVarificationService.updateMobileNo(obj).subscribe((res) => {
        //console.log("update mobile===>",res);
        if (res) {
          this.variOtpFlag = !this.variOtpFlag;
          this.toastrService.success(
            this.translate.instant("HOME.Mobile_number_successfully_updated") +
            " :)"
          );
          //alert("Mobile number successfully updated:)");
        }
      });
    } else {
      this.toastrService.error(
        this.translate.instant("HOME.Invalid_OTP") + "!!",
        this.translate.instant("HOME.try_newly_sent_otp")
      );
      //alert("Invalid OTP!! try newly sent otp-");
    }
  }

  // show passwor==>
  cfpeyeslashclass = false;
  fpeyeslashclass = false;
  cfptoggleClassNType(id: string) {
    if ("password-fieldC" === id) {
      this.cfpeyeslashclass = !this.cfpeyeslashclass
      if (document.getElementById("password-fieldC").getAttribute("type") === "password") {
        document.getElementById("password-fieldC").setAttribute("type", "text");
      } else {
        document.getElementById("password-fieldC").setAttribute("type", "password");
      }
    }
    if ("password-fieldS" === id) {
      this.fpeyeslashclass = !this.fpeyeslashclass
      if (document.getElementById("password-fieldS").getAttribute("type") === "password") {
        document.getElementById("password-fieldS").setAttribute("type", "text");
      } else {
        document.getElementById("password-fieldS").setAttribute("type", "password");
      }
    }
  }

  proceedToDashboard() {
    //change session object ===>
    this.docDtl.userMobileNo = this.mobileNo;

    // update user detail on local storage ==>
    let value = JSON.stringify(this.docDtl);
    let key = "LITMUS_USER_DETAILS";
    this.authenticationService.setAuthKey(key, value);

    //route to dashboard ===>
    this.closeModal("mobVariModal");
    //console.log("routing to dashboard===> after confirm mobile no===>");
    //update fcm token ===>
    this.messagingService.updateTokenToDB(this.docDtl["doctorId"]).subscribe(
      (res) => {
        //console.log("response token fcm==>", res);
        if (res && res["returnValue"] === "1") {
          console.log("FCM Token successfully Updated!!");
        } else {
          //console.log("Error happen during api");
        }
      },
      (error) => {
        console.log("Error:: ", error);
      }
    );
    this.toastrService.success(
      this.translate.instant("HOME.logged_in_Successfully") + " :)"
    );
    sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
    localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
    this.router.navigate(["/", "dashboard"]);
  }

  // password policy validator ===>
  private passwordPolicyValidator(password: string): boolean {
    let regep = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[(!@#$%^&_,.<>*)])[A-Za-z\d(!@#$%^&_,.<>*)]{6,15}$/);
    //console.log(regep.test(password));"//^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$"
    // ingore reaptation (.)\1{3,}
    return regep.test(password);
  }

  //test ===>
  testfun() {
    let dto = {}
    var oHeader = { alg: 'HS512', typ: 'JWT' };
    // var tNow = KJUR.jws.IntDate.get('now');
    // var tEnd = KJUR.jws.IntDate.get('now + 1day');
    // Sign JWT, password=616161
    var sHeader = JSON.stringify(oHeader);
    var sPayload = JSON.stringify(dto);
    //var sJWT = KJUR.jws.JWS.sign("HS512", sHeader, sPayload, "7d07212ea152ccb94e2372b20dd3ec8d27de7158d6c0869c8330a2f55c3238ca0804b521690b461d7781c91a168e9e25553f8887d13cd2c732bce5b32b79de9f");
    var tokentCF = KJUR.jws.JWS.sign("HS512", sHeader, sPayload, { utf8: "7d07212ea152ccb94e2372b20dd3ec8d27de7158d6c0869c8330a2f55c3238ca0804b521690b461d7781c91a168e9e25553f8887d13cd2c732bce5b32b79de9f" });
    //var tokenbase64 = KJUR.jws.JWS.sign("HS512", sHeader, sPayload, { b64: "7d07212ea152ccb94e2372b20dd3ec8d27de7158d6c0869c8330a2f55c3238ca0804b521690b461d7781c91a168e9e25553f8887d13cd2c732bce5b32b79de9f" });
    //console.log("token::", sJWT);
    console.log("tokentCF::", tokentCF);
    //console.log("tokenbase64::", tokenbase64);
    var md5 = require('md5');
    console.log(md5('1234'));
  }
  childWindow;
  openFaceLoginWindow(){
    var screenWidth = window.screen.width;
    var screenHeight = window.screen.height;
    var width = Math.round(screenWidth * 0.4);
    var height = Math.round(screenHeight * 0.7);
  
    // Calculate the left and top positions to center the window
    var left = Math.round((screenWidth - width) / 2);
    var top = Math.round((screenHeight - height) / 2) - 20;
  
    // Open a new window with a form
    let urlToOpen = ApiConstants.doctorFaceLivenessUrl;
    this.childWindow = window.open(
      urlToOpen+"?type=login",
      "_blank",
      "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top
    );
  
  }

  faceLogin(resp) {
      // this.loginService.getLitmusUserLoginDtl(this.userInput).subscribe(
      // (resp) => {
      this.loginLoader = false;
      console.log("Doctor Detail on login ::",resp);
      this.docDtl = resp;
      let secureToken = "face-login-token";

      //and relogin after password change
      this.createCaptcha();

      this.mobvarflag = this.docDtl["mobileVerficationFlag"];
      localStorage.setItem("JWT_KEY", secureToken);
      //console.log(this.docDtl, secureToken);
      //this.mobvarflag = resp["mobileVerficationFlag"];
      //console.log(this.docDtl);
      if (this.docDtl) {
        let value = JSON.stringify(this.docDtl);
        let key = "LITMUS_USER_DETAILS";
        if (this.docDtl.resStatus === "1" || this.docDtl.resStatus === "3") {
          //console.log("check mobile number");
          // just after login update to a secure password by changing password mandatory===>
          if (!this.passwordPolicyValidator(String(this.f.password.value))) {
            this.securePasswordChangeFlag = true;
            this.verifyOTPValue = true;
            this.openFPModal("forgotPassDlg");
            this.createCaptchaforFP();
            this.loadScript("passwordValidationAfterview.js");
            $(".toggle-password").on('click', function () {
              $(this).toggleClass("fa-eye fa-eye-slash");
              var input = $($(this).attr("toggle"));
              if (input.attr("type") == "password") {
                input.attr("type", "text");
              } else {
                input.attr("type", "password");
              }
            });
            return false;
          }

        } else if (
          this.docDtl.resStatus === "4" ||
          this.docDtl.resStatus === "5"
        ) {
          let key2 = "APPLI_STATUS";
          let value2 = JSON.stringify(this.docDtl);
          this.authenticationService.setAuthKey(key2, value2);
        } else {
          // create new captcha on failed login
          this.createCaptcha();
          (<HTMLInputElement>document.getElementById("cpatchaTextLogin")).value = "";
          this.validateLogin = !this.authenticationService.isLoggedIn();
          this.toastrService.error(
            this.translate.instant("HOME.Facial_biometrics_error_message")
          );
          //alert("invalid user mail or password");
        }

        if (this.docDtl.resStatus === "1") {
          // if mobile varification flag is Y than show modal else redirect to dashboard direct without showing dialog
          if (
            this.docDtl.userMobileNo === null ||
            this.docDtl.userMobileNo === ""
          ) {
            this.invalidNoDB = true;
            this.mobileValidationFlag = false;
          }

          // console.log("Disclaimer messages::1", this.docDtl['renderLoginDisclaimer']);
          // console.log("Disclaimer messages::2", this.docDtl['userType'] === 'Doctor');
          if (this.docDtl['renderLoginDisclaimer'] === "Y" && this.docDtl['userType'] === 'Doctor') {
            this.loginService.getDisclaimerMessages('DOCTOR').subscribe(
              (res) => {
                console.log("Disclaimer messages::", res);
                this.disclaimerMessagesList = res;
                if (this.disclaimerMessagesList && this.disclaimerMessagesList.length > 0) {
                  // show in modal
                  this.openDisclaimerModal();
                } else {
                  // process next step;
                }
              },
              (error) => {
                console.error("Error::login component::", error);
              }
            );
          } else {
            if (this.mobvarflag === "Y") {
              //alert("open mobile varification called===>");
              let modalId = "mobVariModal";
              // this will open mobile varificat modal ====>
              this.openModal(modalId);
            } else {
              //console.log("routing to dashboard===>");

              //set token to local storage ===>
              if (
                this.docDtl.resStatus === "1" ||
                this.docDtl.resStatus === "3"
              ) {
                this.authenticationService.setAuthKey(key, value);
              }

              //update fcm token ===>
              this.messagingService
                .updateTokenToDB(this.docDtl["doctorId"])
                .subscribe(
                  (res) => {
                    //console.log("response token fcm==>", res);
                    if (res && res["returnValue"] === "1") {
                      console.log("FCM Token successfully Updated!!");
                    } else {
                      //console.log("Error happen during api");
                    }
                  },
                  (error) => {
                    console.log("Error:: ", error);
                  }
                );
              this.toastrService.success(
                this.translate.instant("HOME.logged_in_Successfully") + ":)"
              );
              sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
              localStorage.setItem("LOCAL_DATA_REFRESH_FLAG", "Y");
              this.router.navigate(["/", "dashboard"]);
            }
          }
        }
        if (this.docDtl.resStatus === "3") {
          this.router.navigate(["dashboard/profile-settings"]);
        }
        if (this.docDtl.resStatus === "4" || this.docDtl.resStatus === "5") {
          sessionStorage.setItem("onlineOfflineStatus", JSON.stringify(true));
          this.router.navigate(["/", "applicationStatus"]);
        }
      } else {
        this.toastrService.error(
          this.translate.instant("HOME.Facial_biometrics_error_message")
        );
        //alert('Error while login!!')
      }
    }
}
