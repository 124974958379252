export class ApiConstants {
    // service url===>
   // public static readonly apiEndPoint = "https://litmusdx.com/helloml";

    //public static readonly apiEndPoint = "https://medikate.org/helloml";

    // public static readonly apiEndPoint = "http://localhost:8080/helloml";
    
    public static readonly apiEndPoint = "https://glocalhospitals.in/helloml";

    // vidoe link url
    public static readonly zoomvideoUrl = "https://litmusdx.com/hellolyfhmis";

    // public static readonly doctorFaceLivenessUrl = "https://hellolyflitmusdxv2.web.app";
    public static readonly doctorFaceLivenessUrl = "https://litmusdx-face-liveness.web.app";

    public static readonly pathPrefix = "./assets/";
    //public static readonly pathPrefix = "";

    constructor() {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% SERVICE ENDPOINT %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%" + ApiConstants.apiEndPoint);
    }
}
